<template>
  <div class="animated fadeIn">
    <b-form v-on:submit.prevent
            novalidate
            autocomplete="off">
      <b-card>
        <div slot="header">
          <h2>Login as</h2>
        </div>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="impersonateUsername" class="eden__label__required">Log in as</label>
              <b-form-select id="impersonateUsername"
                             ref="impersonateUsername"
                             :options="this.impersonateUsernames"
                             v-model="form.impersonateUsername"
                             aria-describedby="impersonateUsernameFeedback"
                             class="impersonateUsernameFeedback"></b-form-select>
              <b-form-invalid-feedback id="impersonateUsernameFeedback">
                This is a required field
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="username" class="eden__label__required">Email</label>
              <b-form-input id="username"
                            ref="username"
                            v-model.trim="form.username"
                            :state="$v.form.username.$dirty ? !$v.form.username.$error : null"
                            aria-describedby="usernameFeedback"
                            placeholder="Email"
                            autocomplete="nope"
                            autocorrect="off"
                            autocapitalize="off"></b-form-input>
              <b-form-invalid-feedback id="usernameFeedback">
                This is a required field
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="password" class="eden__label__required">Password</label>
              <b-form-input type="password"
                            id="password"
                            ref="password"
                            v-model.trim="form.password"
                            aria-describedby="passwordFeedback"
                            :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                            placeholder="Password"></b-form-input>
              <b-form-invalid-feedback id="passwordFeedback">
                This is a required field
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col cols="12" xl="2" lg="3" md="6">
            <b-form-group>
              <b-button v-on:click="login"
                        ref="save"
                        id="save"
                        variant="primary"
                        class="w-100">
                <i class="fa fa-user mr-2"></i>
                Login
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
    <b-modal id="impersonateFailure"
             title="Access Denied"
             size="md"
             ok-only
             ok-variant="danger"
             ok-title="Access Denied"
             @ok="redirectToStandardLogin">
      <b-row>
        <b-col cols="12">
          Only those with the TechAdmin role can impersonate other users
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="impersonateSuccess"
             title="Impersonating User"
             size="md"
             ok-title="Impersonate"
             @ok="impersonateUser"
             cancel-title="Logout"
             @cancel="logout">
      <b-row>
        <b-col cols="12">
          You are impersonating {{ form.impersonateUsername }}
        </b-col>
      </b-row><br />
      <b-row>
        <b-col cols="12">
          {{ form.impersonateUsername }} has the following roles:
        </b-col>
      </b-row><br />
      <b-row>
        <b-col cols="12">
          {{ impersonateRoles }}
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  export default {
    mixins: [
      validationMixin,
    ],

    components: {
      
    },

    data() {
      return {
        form: {
          impersonateUsername: -1,
          username: null,
          password: null,
        },
        impersonateUsernames: [
          { value: -1, text: 'Please select an employee to impersonate' },
        ],
        impersonateRoles: [

        ],
      }
    },

    validations: {
      form: {
        impersonateUsername: { isValid: val => val != -1 },//{ isValid: val => val != -1 },//{ required },
        username: { required },
        password: { required },
      },
    },

    methods: {
      async login() {
        // Form validation
        this.$v.form.$touch()

        if (this.$v.form.$anyError) {
          this.$toasted.global.error({
            message: 'Please correct errors in form',
          })
          return
        }

        const loader = this.$loading.show()
        try {
          const { data } = await this.$request({
            url: '/api/auth/login.impersonate.user',
            method: 'post',
            data: this.form,
          })
          const roles = data?.user?.appRoles?.map(r => r.appRole.name)

          //redirect if not in TechAdmin role
          // this made more sense when I did a call to '/api/auth/login' first before doing a call to '/api/auth/login.impersonate.user'. need to figure out how to do it with the all in one.
          /*
          if (!roles.includes('TechAdmin')) {
            this.$bvModal.show('impersonateFailure')
            return false;
          }
          */

          this.$store.commit('authToken', data?.token)
          this.$store.commit('isImpersonating', true)
          this.impersonateRoles = roles

          // Store our app version in local storage
          try {
            const res = await this.$request({
              method: 'get',
              url: '/build_version.json',
              headers: {
                Authorization: `Bearer ${data?.token}`,
              },
              params: {
                __ts: Date.now(),
              },
            })

            console.log(`Stored app version: ${this.$store.getters.appVersion}`)
            console.log(`Deployed app version: ${res.data.version}`)
            this.$store.commit('appVersion', res.data.version)
          } catch (e) {
            console.log(e);
          }

          this.$bvModal.show('impersonateSuccess')
        } catch (e) {
          this.$log.error('Error imperonsating user', e)
          this.$toasted.global.error({
            message: 'Invalid credentials',
          })
          return
        } finally {
          loader.hide()
        }

      },

      async impersonateUser() {
        console.log("impersonateUser")
        // Redirect if we ended up here from a session expire
        try {
          if (this.$store.getters.loginRedirect) {
            const redir = this.$store.getters.loginRedirect
            this.$store.commit('loginRedirect', null)
            return this.$router.push(redir)
          }
        } catch (e) {
          this.$log.error('Error redirecting user', e)
        }

        this.$router.push('/')
      },

      async getImpersonateUsernames() {
        const data = await this.$request({
          url: '/api/auth/list',
          method: 'get',
        })
        
        var userList = data?.data?.data || []

        // add a fullName property to each user
        userList.forEach(user => {
          const nameParts = user.username.split("@")[0]
          var firstName = nameParts.split(".")[0] || ''
          var lastName = nameParts.split(".")[1] || ''
          firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
          lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
          user.fullName = `${firstName} ${lastName}`
        })

        // sort users by first name and then last name
        var users = userList.sort((a, b) => {
          const namePartsA = a.username.split("@")[0]
          const firstNameA = (namePartsA.split(".")[0] || '').toUpperCase()
          const lastNameA = (namePartsA.split(".")[1] || '').toUpperCase()
          const namePartsB = b.username.split("@")[0]
          const firstNameB = (namePartsB.split(".")[0] || '').toUpperCase()
          const lastNameB = (namePartsB.split(".")[1] || '').toUpperCase()

          if (firstNameA < firstNameB) return -1
          if (firstNameA > firstNameB) return 1
          if (lastNameA < lastNameB) return -1
          if (lastNameA > lastNameB) return 1
          return 0
        })

        users.map(user => this.impersonateUsernames.push({ value: user.username, text: `${user.fullName} - ${user.username}` }))
      },

      async redirectToStandardLogin() {
        await this.$router.push('/login')
      },

      async logout() {
        try {
          this.$store.commit('reset')
        } catch (e) {
          this.$log.error('Error logging out user', e)
        }
        try {
          await this.$router.push('/login-impersonate')
        } catch (e) { }
        window.location.href = window.location.href
      },
    },

    async mounted() {
      return await Promise.all([
        await this.getImpersonateUsernames(),
      ])
    },
  }
</script>
